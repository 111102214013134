import * as React from "react";
import { HeadFC } from "gatsby";
import { DesktopLayoutBasicColumn } from "../../components/layout/desktop-layouts/desktop-layout-basic-column";
import { DesktopId } from "../../app/desktops";
import { ProgramWindow } from "../../components/gui/organisms/program-window/program-window";
import { PostContent } from "../../components/gui/atoms/post-content";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import TextShadowPrismJsWebm from "../../images/posts/text-shadow-prismjs.webm";

export const postTextShadowAwfulTitle =
  "CSS text-shadow is, somehow, still laggy (sometimes) - use drop-shadow instead (maybe).";

export const postTextShadowAwfulBlurb = (
  <>
    <p>
      <code>text-shadow</code> can sometimes be laggy in Chrome. It might be
      better to use <code>drop-shadow</code> in some cases.
    </p>
  </>
);

export default () => {
  return (
    <DesktopLayoutBasicColumn
      desktopId={DesktopId.POSTS_TEXT_SHADOW}
      canWindowsRearrange={true}
    >
      <ProgramWindow windowId="post-body-text-shadow" title=" ">
        <PostContent>
          <h1>{postTextShadowAwfulTitle}</h1>
          <p>
            I was looking for a lightweight syntax highlighter, and I started
            reading up on{" "}
            <OutboundLink href="https://prismjs.com" target="_blank">
              PrismJS
            </OutboundLink>
            . To my surprise, there was a massive framerate drop when I scrolled
            to the very bottom of the page:
          </p>
          <div style={{ textAlign: "center", margin: "2em 0" }}>
            {/* <img
                src="https://placehold.co/600x400"
                alt="A screenshot of the compiled test program successfully running in DOSBox, with rows of VGA colours on screen."
              /> */}
            <video
              src={TextShadowPrismJsWebm}
              controls={true}
              autoPlay={false}
              style={{ width: 800, maxWidth: "100%" }}
            />
            <p>
              On the left: the laggy <code>text-shadow</code>-based version of
              the PrismJS webpage.
              <br />
              On the right: a <code>drop-shadow</code>-based version of the same
              page.
            </p>
          </div>
          <p>
            After some investigating, it turned out that the footer section,
            with the CSS property <code>text-shadow</code>, was to blame. But
            this lag only happened in Chrome and Edge - both based on the Blink
            rendering engine - and not in Safari or Firefox. This was a bit of a
            surprise to me since <code>text-shadow</code> is an <em>ancient</em>{" "}
            CSS3 property - Chrome should really have optimized this by now.
          </p>

          <h2>
            The fix:{" "}
            <OutboundLink
              href="https://developer.mozilla.org/en-US/docs/Web/CSS/filter-function/drop-shadow"
              target="_blank"
            >
              <code>drop-shadow()</code>
            </OutboundLink>
          </h2>
          <p>
            <code>drop-shadow()</code> can be a direct replacement, with some
            caveats.
          </p>
          <pre>{`.shadow {
  /* drop-shadow(offset-x offset-y blur-radius color) */
  filter: drop-shadow(4px 8px 12px black);

  /* Multiple filters can be combined in one rule: */
  filter: drop-shadow(4px 8px 12px black) drop-shadow(2px 16px 4px pink);
}`}</pre>

          <p>
            Depending on your use case, you may need to selectively apply{" "}
            <code>drop-shadow</code> to individual content nodes. The{" "}
            <code>drop-shadow()</code> effect is based on the entire element's
            alpha mask, so it'll work more like a <code>box-shadow</code> if the
            element has an opaque background.
          </p>
          <pre>{`<div style="background: pink">
  <p class="shadow">Content here</p>
  <ul style="background: white">
    <li class="shadow">Item 1</li>
    <li class="shadow">Item 2</li>
    <li class="shadow">Item 3</li>
  </ul>
</div>`}</pre>

          <h2>Why does this work?</h2>
          <p>
            The <code>filter</code> attribute in CSS is used for applying SVG
            Filters, in addition to just simple primitives like blurs and
            shadows. That's probably why it's more performant than{" "}
            <code>text-shadow</code>, which probably was never rewritten to make
            use of the SVG-focused optimizations.
          </p>

          <h2>The old, no-longer-working fix</h2>
          <p>
            Apparently, this lag has been an issue dating back to its
            introduction in CSS3.{" "}
            <OutboundLink
              href="https://stackoverflow.com/a/5928243"
              target="_blank"
            >
              This old Stack Overflow post
            </OutboundLink>{" "}
            from 2011 suggests that triggering hardware acceleration could work
            in WebKit:
          </p>
          <pre>
            {`.shadow {
  text-shadow: 0 0 black;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}`}
          </pre>
          <p>
            But I haven't been able to get this to resolve the PrismJS homepage
            lag with Chrome (now based on Blink) in 2023.
          </p>

          <h2>Edit: Actually, I was half-right...</h2>
          <p>
            I took another look to see why that particular{" "}
            <code>text-shadow</code> on PrismJS.com was so laggy. I realized
            that the lag was due to the combination of two factors: the{" "}
            <code>text-shadow</code> was being rendered on top of the{" "}
            <code>fixed</code>-positioned background image. In this case,
            removing the unnecessary <code>fixed</code> keyword also eliminated
            the lag.
          </p>
          <p>
            But this whole <code>drop-shadow</code> idea could still be helpful
            if you run into this kind of issue in a similar context, where you
            do need to preserve some otherwise expensive graphics. We'll see if
            Chrome patches this lag in a future update.
          </p>
        </PostContent>
      </ProgramWindow>
    </DesktopLayoutBasicColumn>
  );
};

export const Head: HeadFC = () => (
  <title>{postTextShadowAwfulTitle} | simontang.dev</title>
);
